import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import './LineChartPage.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartPage = () => {
  const { state: { product, history } = {} } = useLocation();

  if (!product || !history) {
    return <div>No product selected</div>;
  }

  return (
    <div>
      <div className="header">
        <img src={product.defaultImageUrl} alt={product.title} className="product-image" />
        <h1>{product.title} - Price History</h1>
      </div>
      <div className="chart-container">
        <LineChart priceHistory={history} />
      </div>
    </div>
  );
};

const LineChart = ({ priceHistory }) => {
  const data = useMemo(() => ({
    labels: priceHistory.map(entry => entry.date),
    datasets: [
      {
        label: 'Discounted Price',
        data: priceHistory.map(entry => entry.discountPrice),
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Price',
        data: priceHistory.map(entry => entry.product.price),
        fill: false,
        backgroundColor: 'rgba(255,99,132,0.4)',
        borderColor: 'rgba(255,99,132,1)',
      },
    ],
  }), [priceHistory]);

  const options = useMemo(() => ({
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `£${context.parsed.y}`;
            }
            return label;
          },
        },
      },
    },
  }), []);

  return <Line data={data} options={options} />;
};

LineChart.propTypes = {
  priceHistory: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    discountPrice: PropTypes.number.isRequired,
    product: PropTypes.shape({
      price: PropTypes.number.isRequired,
    }).isRequired,
  })).isRequired,
};

export default ChartPage;