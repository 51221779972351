import React, { useState } from 'react';
import axios from 'axios';
import './SearchPage.css';
import ProductTile from './ProductTile';
import { useNavigate } from 'react-router-dom';

const BACKEND_URL = 'https://flashbuild.app/api';

const SearchPage = () => {

  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleProductClick = async (product) => {
    var history = [];
    try {
      console.log('Fetching data for', product);
      const response = await axios.get(`${BACKEND_URL}/history`, {
        params: { product_id: product.id },
      });
      history = response.data;
      console.log('Fetching response for', history);
    } catch (error) {
      console.error('Error fetching data', error);
    }
    console.log('Navigating to chart', product, history);
    navigate('/chart', { state: { product, history } });
  };

  return (
    <div>
      <h1>Search Tesco Product</h1>
      <a href="/discounts">Discounts</a>
      <Search onDataFetched={setData} />
      <div className="product-tiles">
        {data.map((item) => (
          <ProductTile key={item._id} product={item.product} onClick={handleProductClick} />
        ))}
      </div>
    </div>
  );
};

const Search = ({ onDataFetched }) => {
  const [title, setTitle] = useState('');

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/search`, {
        params: { title }
      });
      onDataFetched(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  return (
    <div className="search-container">
      <input
        className="search-input"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Search..."
      />
      <button className="search-button" onClick={handleSearch}>Search</button>
    </div>
  );
};

export default SearchPage;
