import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Top100ProductsTable from './Top100ProductsTable';
import ProductTable from './ProductTable';
import { Tabs, Tab, Box } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

const BACKEND_URL = 'https://flashbuild.app/api';

const TAB_INDICES = {
  DISCOUNTED: 0,
  INCREASED: 1,
  CHEAP: 2,
  COSTLY: 3,
};

const DiscountsPage = () => {

  const [tabValues, setTabValues] = useState({
    cheapTab: 0,
    costlyTab: 0,
    selectedTab: 0,
  });
  const [items, setItems] = useState({
    discounted: [],
    increased: [],
    costlyBySuperDepartment: {},
    cheapBySuperDepartment: {},
  });

  const navigate = useNavigate();

  const fetchCheapProducts = useCallback(async () => {
    if (Object.keys(items.cheapBySuperDepartment).length > 0) {
      return;
    }
    await fetchAndGroupProducts(`${BACKEND_URL}/cheapest`, setItems);
  }, [items.cheapBySuperDepartment]);

  const fetchCostlyProducts = useCallback(async () => {
    if (Object.keys(items.costlyBySuperDepartment).length > 0) {
      return;
    }
    await fetchAndGroupProducts(`${BACKEND_URL}/costly`, setItems);
  }, [items.costlyBySuperDepartment]);

  const fetchDiscountedItems = useCallback(async () => {
    if (items.discounted.length > 0 && items.increased.length > 0) {
      return;
    }
    try {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const params = {
        start_date: formatDate(yesterday),
        end_date: formatDate(today),
      };
      const response = await axios.get(`${BACKEND_URL}/analytics`, { params: params });
      const data = await response.data;
      setItems((prevItems) => ({
        ...prevItems,
        discounted: data.discounted,
        increased: data.increased,
      }));
    } catch (error) {
      console.error('Error fetching discounted items:', error);
    }
  }, [items.discounted.length, items.increased.length]);

  useEffect(() => {
    switch (tabValues.selectedTab) {
      case TAB_INDICES.DISCOUNTED:
      case TAB_INDICES.INCREASED:
        fetchDiscountedItems();
        break;
      case TAB_INDICES.CHEAP:
        fetchCheapProducts();
        break;
      case TAB_INDICES.COSTLY:
        fetchCostlyProducts();
        break;
      default:
        break;
    }
  }, [tabValues.selectedTab, fetchDiscountedItems, fetchCheapProducts, fetchCostlyProducts]);

  const handleTabChange = (_, newValue) => {
    setTabValues((prev) => ({ ...prev, selectedTab: newValue }));
  };

  const handleViewProductClick = async (item) => {
    var history = [];
    try {
      const response = await axios.get(`${BACKEND_URL}/history`, {
        params: { product_id: item['product.id'] },
      });
      history = response.data;
    } catch (error) {
      console.error('Error fetching data', error);
    }
    const product = {
      id: item['product.id'],
      title: item['product.title_today'],
      defaultImageUrl: 'https://via.placeholder.com/150',
    };
    navigate('/chart', { state: { product, history } });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleCheapTabChange = (_, newValue) => {
    setTabValues((prevValues) => ({ ...prevValues, cheapTab: newValue }));
  };

  const handleCostlyTabChange = (_, newValue) => {
    setTabValues((prevValues) => ({ ...prevValues, costlyTab: newValue }));
  };

  const fetchAndGroupProducts = async (url, setItems) => {
    try {
      const response = await axios.get(url);
      const data = response.data;
      const groupedProducts = data.reduce((acc, item) => {
        const department = item['product.superDepartmentName'];
        if (!acc[department]) {
          acc[department] = [];
        }
        acc[department].push(item);
        return acc;
      }, {});
      if (url.includes('cheapest')) {
        setItems((prevItems) => ({
          ...prevItems,
          cheapBySuperDepartment: groupedProducts,
        }));
      } else {
        setItems((prevItems) => ({
          ...prevItems,
          costlyBySuperDepartment: groupedProducts,
        }));
      }
    } catch (error) {
      console.error(`Error fetching products from ${url}:`, error);
    }
  };

  return (
    <Box>
      <Tabs value={tabValues.selectedTab} onChange={handleTabChange}>
        <Tab icon={<ArrowDownwardIcon />} iconPosition="start" label="Discounted" />
        <Tab icon={<ArrowUpwardIcon />} iconPosition="start" label="Increased" />
        <Tab icon={<AttachMoneyIcon />} iconPosition="start" label="Cheap" />
        <Tab icon={<MoneyOffIcon />} iconPosition="start"label="Costly" />
      </Tabs>
      <Box hidden={tabValues.selectedTab !== 0}>
        {
          items.discounted.length > 0 && (<ProductTable type="discounted" items={items.discounted} handleViewProductClick={handleViewProductClick} />)
        }
      </Box>
      <Box hidden={tabValues.selectedTab !== 1}>
        {
          items.increased.length > 0 && (<ProductTable type="increased" items={items.increased} handleViewProductClick={handleViewProductClick} />)
        }
      </Box>
      <Box hidden={tabValues.selectedTab !== 2}>
        {
          Object.keys(items.cheapBySuperDepartment).length > 0 && (
            <Top100ProductsTable tabValue={tabValues.cheapTab} handleTabChange={handleCheapTabChange}
              productsBySuperDepartment={items.cheapBySuperDepartment}
              handleViewProductClick={handleViewProductClick} />
          )
        }
      </Box>
      <Box hidden={tabValues.selectedTab !== 3}>
        {
          Object.keys(items.costlyBySuperDepartment).length > 0 && (
            <Top100ProductsTable tabValue={tabValues.costlyTab} handleTabChange={handleCostlyTabChange}
              productsBySuperDepartment={items.costlyBySuperDepartment}
              handleViewProductClick={handleViewProductClick} />
          )
        }
      </Box>
    </Box>
  );
};

export default DiscountsPage;
