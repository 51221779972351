import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChartPage from './LineChartPage';
import SearchPage from './SearchPage';
import DiscountsPage from './DiscountsPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SearchPage />} />
        <Route path="/chart" element={<ChartPage />} />
        <Route path="/discounts" element={<DiscountsPage />} />
      </Routes>
    </Router>
  );
};

export default App;