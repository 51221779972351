import React from 'react';
import './ProductTile.css';

const ProductTile = ({ product, onClick }) => {
  const { title, brandName, defaultImageUrl, price, unitPrice, unitOfMeasure } = product;

  return (
    <div className="product-tile" onClick={() => onClick(product)}>
      <img src={defaultImageUrl} alt={title} />
      <h2>{title}</h2>
      <p>Brand: {brandName}</p>
      <p>Price: £{price}</p>
      <p>Unit Price: £{unitPrice} per {unitOfMeasure}</p>
    </div>
  );
};

export default ProductTile;