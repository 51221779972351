import React from 'react';
import LineChart from '@mui/icons-material/StackedLineChart';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Tooltip, Link } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ProductTable = ({ type, items, handleViewProductClick }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Price History</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item['product.id']}>
              <TableCell>
                <Link href={`https://www.tesco.com/groceries/en-GB/products/${item['product.id']}`} target="_blank" underline="hover">
                  {item['product.title_today']}
                </Link>
              </TableCell>
              <TableCell>£{item['discountPrice_today']}</TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {type === 'discounted' ? <ArrowDownwardIcon style={{ color: 'green' }} /> : <ArrowUpwardIcon style={{ color: 'red' }} />}
                  <span style={{ marginLeft: '5px' }}>{item['percentage']}%</span>
                </div>
              </TableCell>
              <TableCell>
                <Tooltip title="View Price Trend">
                  <Button variant="text" color="primary" startIcon={<LineChart />} onClick={() => handleViewProductClick(item)}>
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;