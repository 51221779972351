
import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import LineChart from '@mui/icons-material/StackedLineChart';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Tooltip, Link } from '@mui/material';

const Top100ProductsTable = ({ tabValue, handleTabChange, productsBySuperDepartment, handleViewProductClick }) => {
  return (
    <Box>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="cheap products tabs">
        {Object.keys(productsBySuperDepartment).map((superDepartment, index) => (
          <Tab key={superDepartment} label={superDepartment} />
        ))}
      </Tabs>
      {Object.keys(productsBySuperDepartment).map((superDepartment, index) => (
        <TabPanel key={superDepartment} value={tabValue} index={index}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Price History</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsBySuperDepartment[superDepartment].map((item) => (
                  <TableRow key={item['product.id']}>
                    <TableCell>
                      <Link href={`https://www.tesco.com/groceries/en-GB/products/${item['product.id']}`} target="_blank" underline="hover">
                        {item['product.title']}
                      </Link>
                    </TableCell>
                    <TableCell>£{item['product.price']}</TableCell>
                    <TableCell>
                      <Tooltip title="View Price Trend">
                        <Button variant="text" color="primary" startIcon={<LineChart />} onClick={() => handleViewProductClick(item)}>
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      ))}
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default Top100ProductsTable;